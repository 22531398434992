<template>
  <div home-23s2>
    <HomeHeader/>
    <OfficialLeague @scheduleModal="scheduleModal" hide-schedule>
      <template #detail>
        <PubgDetail/>
      </template>
    </OfficialLeague>
    <div class="pubg-scrim">
      <PubgScrim/>
    </div>
    <client-only><MyInfo /></client-only>
    <PubgGoTop/>
  </div>
</template>

<script>
import OfficialLeague from '@/views/components/common/gameHome/OfficialLeague.vue';
import PubgScrim from '@/views/components/common/gameHome/PubgScrim.vue';
import PubgGoTop from '@/views/components/common/button/PubgGoTop.vue';
import HomeHeader from '@/views/components/common/gameHome/HomeHeader.vue';
import PubgDetail from '@/views/components/pls_2023_2/gameHome/PubgDetail.vue';
import MyInfo from '@/views/components/pls_2023_2/gameHome/MyInfo.vue';
import ScheduleModal from '@/views/components/pls_2023_2/gameHome/ScheduleModal.vue';

export default {
  name: 'PubgHome',
  components: {
    PubgDetail,
    PubgGoTop,
    PubgScrim,
    OfficialLeague,
    HomeHeader,
    MyInfo,
  },
  methods: {
    toInit() {
      setTimeout(() => {
        const target = document.querySelector('.league');
        this.$scroll.scrollMove(target.offsetTop + (this.matchedMediaDevice === 'M' ? 450 : 700), 1000);
      }, 200);
    },
    scheduleModal() {
      this.$modal(ScheduleModal);
    },
  },
  mounted() {
    if(this.$route.query.page && this.$route.query.page === 'arena') this.toInit();
  },
};

</script>
<style lang="less">
@import '~@/less/proj.less';
[home-23s2] {
  .pubg-scrim { .bgc(#EBEBF0) }
}
</style>
