<template>
  <div pubg-detail-23s2>
    <div class="contents-title">
      <div class="title-section">
        <PItem tag="img" class="pubg-logo" src="/img/pages/pubg/intro/tl/bg_key_logo.svg" />
        <PItem tag="img" src="/img/pages/pls202302/to-be-a-next-pro.svg" />
        <PItem tag="h2">PUBG 이스포츠 아마추어 대회</PItem>
      </div>
    </div>
    <div class="contents-holder">
      <PItem class="contents-section">
        <div class="contents-box" v-for="(item, index) in playData" :key="`item-${index}`">
          <div class="main">
            <span class="tag">{{ item.title }}</span>
            <div class="date-holder">
              <p class="date">{{ item.date }}</p>
              <p class="date-detail" v-html="item.dateDetail" />
            </div>
          </div>
          <div class="sub">
            <p class="rule">{{ item.rule }}</p>
            <div class="line"></div>
            <p class="description" v-if="item.description" v-html="item.description"></p>
            <p class="detail" v-html="item.detail"></p>
          </div>
        </div>
      </PItem>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PubgDetail',
  data() {
    return {
      playData: [
        {
          title: '예선',
          date: '5월 8일 / 5월 15일 / 5월 22일',
          dateDetail: '매주 월요일 진행',
          description: '3주 동안 매주 월요일 예선 진행',
          detail: '매주 예선 상위 16팀 선발',
          rule: '예선 경기 방식',
        },
        {
          title: '본선',
          date: '5월 9일 / 5월 16일 / 5월 23일',
          dateDetail: '매주 화요일 진행',
          description: '3주 동안 매주 화요일 본선 진행',
          detail: '총 16팀 결선 진출<br class="tl" /><em> <s>주차별 본선 1위 3팀 + PWS 진출 비시드 5팀</s> <s>+ 2023 게임의 민족 플레이어 상위 2팀</s> <s>+ 본선 누적 포인트 상위 6팀</s></em>',
          rule: '본선 경기 방식',
        },
        {
          title: '결선',
          date: '5월 29일 / 5월 30일',
          dateDetail: '온라인 생방송 진행',
          description: '2일간 10Match 포인트로 순위 산정',
          detail: '<s>2023 PGS 2 Qualifier</s> <s>시드권 부여</s>',
          rule: '결선 경기 방식',
        },
      ],
    };
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[pubg-detail-23s2] { .font-spoqa; .max-w(335); .mh-c;
  .contents-title { .tc; .mt(52); .mb(32);
    .title-section {
      > img {
        &:nth-of-type(1) {.wh(304, 106); .o(.3); .tr-o(.75s, 0s);
          transform+: scale(.9); transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
          .intro-up(2, { .o(1); });
        }
        &:nth-of-type(2) {.wh(154, 24); .mt(22); .mb(10); .ani-base; .intro-up(1, {.ani-on}); }
      }
      > h2 { .fs(24, 24); .ls(-1); .ani-base; .intro-up(1, {.ani-on});
        > em { .ib; .fs(24, 24); .c(#f6ea46); .ls(-1); .mb(10);}
      }
    }
  }
  .contents-holder { .tl;
    .contents-section { .ani-base;
      .intro-up(1, {.ani-on});

      .contents-box { .mh-c; .mb(12);
        p { .mb(6);}
        .main { .bgc(rgba(0, 0, 0, 0.4)); .wh(100%, auto); .pt(20); .pl(10);
          .tag { .flex-center; display: inline-flex; .wh(44, 22); .br(20); .bgc(#5b5c61);.fs(12, 12); .bold; }
          .date-holder { .ib; .vat; .ml(8);
            .date { .fs(12, 12); .bold;}
            .date-detail { .fs(12, 16); .regular; .c(#a4a4a4); .mb(10); }
          }
        }
        &:nth-of-type(3) {
          .main {
            .tag { .bgc(#d13639);}
          }
        }

        .sub { .wh(100%, auto); .inline-flex; flex-direction: column; .justify-start; .pl(62); .pt(12); .rel; .vat; .tl; .bgc(rgba(0, 0, 0, 0.4)); .pb(20);
          .rule { .hide;}
          .line { .abs; .lt(62, 0); .wh(264, 1); .bgc(#765793); }
          .description { .fs(12, 12);
            > br { .hide; }
            span { .c(#a4a4a4); }
          }
          .detail { .pr(8); .fs(16, 15); .c(#f6ea46); .bold;
            > em { .fs(12, 15); .regular; }
            s { .ib; }
          }
        }
      }
    }
  }

  @media (@tl-up) {
    max-width: none;
    .contents-title { .w(100%); .mb(80); .mt(72);
      .title-section {
        > img {
          &:nth-of-type(1) {.wh(484, 170);}
          &:nth-of-type(2) { .wh(256, 40); .mt(37); .mb(16); .block; .mh-c; }
        }
        > h2 {.fs(48, 48);}
      }
    }
    .contents-holder { .tc;
      .contents-section {
        .contents-box { .ib; .calc-w(100%/3, 12); .mr(12); .mb(0);
          &:last-of-type { .mr(0);}
          .main { .tc; .w; .h(120); .mb(8); .pl(0);
            .tag { .flex; .wh(60, 32); .mh-c; .mb(12); .fs(14); }
            .date-holder { .m;
              .date-detail { .fs(14, 20); }
            }
          }
          .sub { .h(155); .tc; .p(0); .-t(#765793, 1px); .-b(#765793, 1px); .justify-center;
            .rule { .block; .c(#946eb7); .font-g; .bold; .fs(16, 16); .mb(8);}
            .line { .rel; .wh(24, 2); .mh-c; .lt; .mb(8); }
            .description { .fs(14, 20); .m(0, 0, 6);
              > br { .block;}
            }
            .detail { .fs(20, 24); .mb(0); .ph(20);
              br.tl { .block; }
              > em { .ib; .mt(4); .fs(14, 16); .bold; }
            }
          }
        }
      }
    }
  }

  @media (@ds-up) {
    .contents-title { .w(100%); .mb(60); .mt(60);
      .title-section {
        > img {
          &:nth-of-type(1) {}
          &:nth-of-type(2) { .mt(37); .mb(16); .block; .mh-c; }
        }
        > h2 {.fs(48, 48);}
      }
    }
    .contents-holder {
      .contents-section {
        .contents-box { .min-w(400); .calc-w(100%/3, 24); .mr(24);
          &:last-of-type { .mr(0);}
          .main { .h(131); .mb(20);
            .tag { .wh(72, 36); .mb(15); .fs(16); }
            .date-holder {
              .date { .fs(16, 16); }
              .date-detail { .mt(10); .fs(16, 16); }
            }
          }
          .sub { .h(200);
            .rule { .fs(20, 20); .mb(12); }
            .line { .mb(12); }
            .description { .fs(16, 24); }
            .detail { .fs(24, 24);
              > em { .mt(10); .fs(14, 18);}
            }
          }
        }
      }
    }
  }
}

</style>
